import React from 'react'
import { Typography } from '@material-ui/core'

export default function ThankYouMsg(props) {
  const { classes, type, show } = props
  return (
    show && (
      <Typography
        color="primary"
        className={classes.thankyouText}
        component="div"
      >
        Thank you for your valuable feedback{' '}
        <span role="img" aria-label="heart">
          ❤️
        </span>
        <br />
        <div>
          We will try to add your requested{' '}
          {type === 'currency' ? 'currencies' : 'webistes'} as soon as possible.
          If you have provided your email id, we will also notify you about it!
        </div>
      </Typography>
    )
  )
}
