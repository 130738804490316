import React from 'react'
import PropTypes from 'prop-types'
import NumberFormt from 'react-number-format'
import { getSymbol } from '../currencyList'
import clsx from 'clsx'

function CurrencyValue(props) {
  const { value, currency, className, ...otherProps } = props
  return (
    <NumberFormt
      value={value}
      decimalScale={2}
      displayType={'text'}
      thousandSeparator={true}
      prefix={getSymbol(currency)}
      className={clsx('currency-value', className)}
      fixedDecimalScale
      {...otherProps}
    />
  )
}

CurrencyValue.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default CurrencyValue
