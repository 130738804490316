import React, { useState } from 'react'
import { submitCurrencySuggestion } from '../services/FeedbackService'
import CurrencySelect from '../components/CurrencySelect'
import { Button, TextField } from '@material-ui/core'
import ThankYouMsg from './ThankYouMsg'

const EMAIL_LEN_LIMIT = 254
const COMMENT_LEN_LIMIT = 500
export default function SuggestCurrency(props) {
  const {
    classes,
    fromCurrency,
    toCurrency,
    setFromCurrency,
    setToCurrency,
  } = props
  const [showMsg, setShowMsg] = useState(false)
  const [emailId, setEmailId] = useState('')
  const [comment, setComment] = useState('')
  const submitForm = (e) => {
    e.preventDefault()
    submitCurrencySuggestion({
      fromCurrency,
      toCurrency,
      emailId,
      comment,
    })
    setFromCurrency('')
    setToCurrency('')
    setEmailId('')
    setComment('')
    setShowMsg(true)
  }
  return (
    <>
      <form onSubmit={submitForm} className={classes.feedbackForm}>
        <div>
          <CurrencySelect
            id="fb-send-currency-select"
            label="Send Currency"
            className={classes.currencySelect}
            onChange={(value) => setFromCurrency(value)}
            value={fromCurrency}
            showFullName
            color="secondary"
          />
          <CurrencySelect
            id="fb-receive-currency-select"
            label="Receive Currency"
            className={classes.currencySelect}
            value={toCurrency}
            onChange={(value) => setToCurrency(value)}
            getOptionDisabled={(opt) => opt.value === fromCurrency}
            showFullName
            color="secondary"
          />
        </div>
        <TextField
          id="fb-email"
          label="Your Email ID (OPTIONAL)"
          value={emailId}
          onChange={(e) => {
            const newValue = e.target.value || ''
            setEmailId(newValue.substring(0, EMAIL_LEN_LIMIT))
          }}
          helperText="We won't spam you! We will notify you once we add suggested currency."
          placeholder={'sample'}
          variant="outlined"
          color="secondary"
          type="email"
          autoComplete="on"
        />
        <TextField
          id="fb-comment"
          label="Comments (OPTIONAL)"
          value={comment}
          onChange={(e) => {
            const newValue = e.target.value || ''
            setComment(newValue.substring(0, COMMENT_LEN_LIMIT))
          }}
          helperText={`Characters left: ${comment.length}/${COMMENT_LEN_LIMIT}`}
          placeholder={'sample'}
          variant="outlined"
          color="secondary"
          multiline
          rowsMax={5}
        />
        <div>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            className={classes.submitBtn}
            disabled={!fromCurrency || !toCurrency}
            data-track-click="true"
            data-track-category="feedback"
            data-track-label="suggest-currency-submit"
          >
            Submit Feedback
          </Button>
        </div>
      </form>
      <ThankYouMsg show={showMsg} classes={classes} type="currency" />
    </>
  )
}
