import axios from 'axios'
import qs from 'query-string'
import { get } from 'lodash'

// axios.defaults.baseURL = 'http://34.67.149.203/api/'
// axios.defaults.baseURL = 'https://fierce-island-51040.herokuapp.com/api/'
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL

export async function getLatestRates(fromCurrency, toCurrency, amount) {
  try {
    if (!fromCurrency || !toCurrency || !amount) {
      throw new Error('Invalid params')
    }
    let params = qs.stringify({
      from: fromCurrency,
      to: toCurrency,
      amount,
    })
    let URL = `/getrates?${params}`
    const response = await axios.get(URL)
    addMinMaxDurationHrs(response)
    return {
      data: response.data,
    }
  } catch (error) {
    console.log(error)
    throw new Error(
      'There was an error fetching data. Please reload or try again in some time'
    )
  }
}
function addMinMaxDurationHrs(response) {
  get(response, 'data.Vendors', []).forEach((item) => {
    const { Duration = '0', DurationType = 'Hrs' } = item

    // extract min-max value from range
    let [min = 0, max = 0] = Duration.trim().split(/\s*-\s*/) // Sample values 2-3 Days, 3-5 Hrs
    if (min && !max) {
      max = min // if not a range, set max value as min
    }

    // convert to numbers
    min = +min
    max = +max

    // convert to hrs
    if (DurationType.toLowerCase() === 'days') {
      item.minDurationHrs = min * 24
      item.maxDurationHrs = max * 24
    } else {
      item.minDurationHrs = min
      item.maxDurationHrs = max
    }
  })
}
