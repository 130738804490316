import moment from 'moment'
export const toLocalTimeStamp = (timeStamp) => {
  // console.log(timeStamp, moment(timeStamp))
  return moment.utc(timeStamp, 'DD-MMM-YYYY hh:mm:ss')
}

export const extractHostName = (url = '') => {
  if (!url) {
    return ''
  }
  let hostname
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }
  //find & remove port number
  hostname = hostname.split(':')[0]
  //find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}

export const sortOptions = [
  {
    key: 'duration',
    label: 'Duration',
    field: 'minDurationHrs',
    order: 'asc',
    disabled: true,
  },
  {
    key: '-duration',
    label: 'Duration (desc)',
    field: 'minDurationHrs',
    order: 'desc',
    disabled: false,
  },
  {
    key: 'name',
    label: 'Name',
    field: 'Name',
    order: 'asc',
    disabled: true,
  },
  {
    key: '-name',
    label: 'Name (desc)',
    field: 'Name',
    order: 'desc',
    disabled: false,
  },
  {
    key: 'netrate',
    label: 'Net rate (asc)',
    field: 'NetRate',
    order: 'asc',
    disabled: false,
  },
  {
    key: '-netrate',
    label: 'Net rate',
    field: 'NetRate',
    order: 'desc',
    disabled: true,
  },
  {
    key: 'rating',
    label: 'Rating (asc)',
    field: 'Rating',
    order: 'asc',
    disabled: false,
  },
  {
    key: '-rating',
    label: 'Rating',
    field: 'Rating',
    order: 'desc',
    disabled: true,
  },
  {
    key: 'receive',
    label: 'You Receive (asc)',
    field: 'TotalAmountReceived',
    order: 'asc',
    disabled: false,
  },
  {
    key: '-receive',
    label: 'You Receive',
    field: 'TotalAmountReceived',
    order: 'desc',
    disabled: true,
  },
  {
    key: 'pay',
    label: 'You Pay (asc)',
    field: 'z_Total_User_Pays',
    order: 'asc',
    disabled: false,
  },
  {
    key: '-pay',
    label: 'You Pay (desc)',
    field: 'z_Total_User_Pays',
    order: 'desc',
    disabled: false,
  },
]
export function parseSortFields(sortByString) {
  let sortOrder = 'asc'
  let sortField = sortByString.toLowerCase()
  if (sortByString.startsWith('-')) {
    sortOrder = 'desc'
    sortField = sortField.substring(1)
  }
  return [sortField, sortOrder]
}

export function scrollToElement(selectorOrElem, offset = 0) {
  let elem = null
  if (selectorOrElem instanceof Element) {
    elem = selectorOrElem
  } else if (typeof selectorOrElem === 'string') {
    elem = document.querySelector(selectorOrElem)
  }
  if (!elem) {
    return
  }
  const top = elem.getBoundingClientRect().top + offset
  window.scrollBy({ top, behavior: 'smooth' })
}
