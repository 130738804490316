import React, { useRef } from 'react'
import { Container, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import backgroundImg from './assets/bg1.jpg'
import clsx from 'clsx'
const headerSize = 64
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: `${headerSize}px`,
    backgroundColor: '#061c3ea1',
    position: 'relative',
  },
  background: {
    // filter: 'blur(8px)',
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    // height: '690px',
    top: `-${headerSize}px`,
    bottom: 0,
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    transform: 'rotate(0deg)',
  },
  heroContainer: (props) => ({
    minHeight: '500px',
    paddingBottom: '50px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: props.hLayout ? 'row' : 'column',
  }),
  heroTextWrapper: (props) => ({
    flexGrow: props.hLayout ? '1' : undefined,
  }),
  formWrapper: (props) => ({
    flex: props.hLayout ? '0 0 450px' : undefined,
    marginBottom: '64px',
    marginTop: '24px',
  }),
  heroTextSection: (props) => ({
    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.9)',
    ...(props.hLayout
      ? {
          textAlign: 'left',
          padding: '24px',
        }
      : {
          textAlign: 'center',
          padding: '30px 0px',
        }),
  }),
  heroText: {
    fontSize: '3rem',
    color: '#ffffff',
    textShadow: '2px 2px 5px rgba(0, 0, 0, 0.9)',
    letterSpacing: '3px',
  },
  heroText2: {
    marginTop: '20px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    // color: theme.palette.secondary.main,
    // textShadow: '2px 2px 10px rgba(225, 225, 225, 0.9)',
    letterSpacing: '2px',
    color: '#ffffff',
  },
  heroText3: {
    marginTop: '15px',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    // color: '#ffffff',
    letterSpacing: '1.5px',
    // textShadow: '2px 2px 10px rgba(225, 225, 225, 0.9)',
  },
  heroHighlight: {
    letterSpacing: '-1px',
    fontSize: '1.2em',
    display: 'inline-block',
    color: '#ffffff',
    '& span': {},
  },
}))

function Hero(props) {
  const horizontalLayout = useMediaQuery('(min-width:800px)')

  // bg height fix starts
  const wrapperRef = useRef(null)
  const bgRef = useRef(null)
  // const [bgHeight, setBgHeight] = useState(700)
  const classes = useStyles({
    hLayout: horizontalLayout,
  })
  // const wrapperHeight = get(wrapperRef, 'current.offsetHeight', null)
  // useEffect(() => {
  //   const interval = window.setInterval(() => {
  //     if (!wrapperHeight) return
  //     if (bgHeight !== wrapperHeight + headerSize) {
  //       console.log('fixing', wrapperHeight)
  //       setBgHeight(wrapperHeight + headerSize)
  //     }
  //   }, 1000)
  //   return () => window.clearInterval(interval)
  // })
  // bg height fix ends

  return (
    <div className={classes.root} ref={wrapperRef}>
      <div
        className={classes.background}
        ref={bgRef}
        // style={{ height: bgHeight }}
      />
      <Container maxWidth="lg">
        <div
          className={clsx(classes.heroContainer, {
            horizontal: horizontalLayout,
            vertical: !horizontalLayout,
          })}
        >
          <div className={classes.heroTextWrapper}>
            <div className={classes.heroTextSection}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.heroText}
              >
                Sending money abroad?
              </Typography>
              <Typography className={classes.heroText2}>
                Stop overpaying fees and maximize your transfers! Compare
                multiple service providers on our website to{' '}
                <span className={classes.heroHighlight}>
                  Get Best <span>FX</span>
                </span>{' '}
                rate!
              </Typography>
            </div>
          </div>
          <div className={classes.formWrapper}>{props.children}</div>
        </div>
      </Container>
    </div>
  )
}

export default Hero
