import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './App'
import { QueryParamProvider } from 'use-query-params'
import { BrowserRouter, Route } from 'react-router-dom'

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <App />
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
