import axios from 'axios'

export async function submitCurrencySuggestion(payload) {
  try {
    if (!payload.fromCurrency || !payload.toCurrency) {
      throw new Error('Invalid params')
    }
    let URL = `/FeedbackCurrency`
    const response = await axios.post(URL, payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export async function submitProvidersSuggestion(payload) {
  try {
    if (!payload.providers || payload.providers.length === 0) {
      throw new Error('Invalid params')
    }
    payload.providers = payload.providers.map((item) =>
      typeof item === 'string' ? { name: item, url: null } : item
    )
    let URL = `/FeedbackVendor`
    const response = await axios.post(URL, payload)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export function getFeedbackProvidersOptions() {
  return [
    {
      name: 'Western Union',
      url: 'westernunion.com',
    },
    {
      name: 'Transferwise',
      url: 'transferwise.com',
    },
    {
      name: 'MoneyGram',
      url: 'moneygram.com',
    },
    {
      name: 'Ria',
      url: 'riamoneytransfer.com',
    },
    {
      name: 'Money2anywhere',
      url: 'money2anywhere.com',
    },
    {
      name: 'Neteller',
      url: 'neteller.com',
    },
  ]
}
