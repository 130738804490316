import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem } from '@material-ui/core'
import CurrencyFlag from './CurrencyFlag'
import currencyList from '../currencyList'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  textField: {
    minWidth: '170px',
    textAlign: 'left',
  },
  menuItem: {},
  menuItemSelected: {},
  currencyFlag: {
    verticalAlign: 'middle',
  },
  currencyCode: { display: 'inline-block', margin: '0 6px' },
  currencyLabel: { color: 'grey' },
})

function CurrencySelect(props) {
  const classes = useStyles()
  const {
    currencyList,
    defaultValue,
    label,
    id,
    onChange,
    className,
    getOptionDisabled,
    showFullName,
    ...otherProps
  } = props
  return (
    <TextField
      id={id}
      select
      label={label}
      defaultValue={defaultValue}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
      className={className}
      classes={{ root: classes.textField }}
      {...otherProps}
    >
      {currencyList.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
          disabled={getOptionDisabled(option)}
        >
          <CurrencyFlag
            currency={option.value}
            className={classes.currencyFlag}
          />
          <span className={`currency-code ${classes.currencyCode}`}>
            {option.value}
          </span>
          {showFullName && (
            <span className={`currency-label ${classes.currencyLabel}`}>
              {option.label}
            </span>
          )}
        </MenuItem>
      ))}
    </TextField>
  )
}

CurrencySelect.propTypes = {
  currencyList: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  getOptionDisabled: PropTypes.func,
  showFullName: PropTypes.bool,
}

CurrencySelect.defaultProps = {
  currencyList,
  label: 'Select Currency',
  defaultValue: '',
  getOptionDisabled: () => false,
  showFullName: false,
}

export default CurrencySelect
