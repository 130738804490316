import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Container } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import MadeInIndiaLogo from './assets/madeinindia-logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.darker,
    color: theme.palette.secondary.darker,
    fontWeight: 'bold',
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
    padding: '32px',
  },
  email: {
    color: 'inherit',
    textTransform: 'none',
    textDecoration: 'none',
    display: 'block',
    marginBottom: '6px',
    fontSize: '1.2em',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  emailIcon: {
    position: 'relative',
    top: '5px',
    marginLeft: '6px',
    marginRight: '3px',
  },
  madeInIndiaLogo: {
    width: '180px',
    marginTop: '24px',
    marginBottom: '36px',
  },
  madeInIndiaText: {
    color: '#979593',
    marginBottom: '20px',
    marginTop: '0px',
    fontFamily: 'fantasy, cursive',
    '& .sm-highlight': {
      fontWeight: 'bold',
    },
  },
}))
function Footer() {
  const classes = useStyles()
  return (
    <footer className={clsx('footer', classes.root)} id="footer">
      <Container maxWidth="md">
        <div>
          <img
            src={MadeInIndiaLogo}
            alt="Made In India"
            className={classes.madeInIndiaLogo}
          />
          {/* <Typography className={classes.madeInIndiaText}>
            Proudly, <span className="sm-highlight">Made in India</span>
          </Typography> */}
        </div>

        <div className={classes.copyright}>
          <a
            href="mailto:contactgetbestfx@gmail.com"
            className={classes.email}
            data-track-click="true"
            data-track-category="feedback"
            data-track-label="email"
          >
            <EmailIcon fontSize="small" className={classes.emailIcon} />
            Email us
          </a>
          Copyright © {new Date().getFullYear()} GetBestFX. All Rights Reserved.
        </div>
      </Container>
    </footer>
  )
}

export default Footer
