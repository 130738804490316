import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import './index.css'

export default function Badge({ label, className = '', bgColor = '' }) {
  return (
    <span className={clsx(`ribbon1`, className)}>
      <span className="badge-label">{label}</span>
    </span>
  )
}

Badge.defaultProps = {
  className: '',
  bgColor: '#f8463f',
}

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  bgColor: PropTypes.string,
}
