/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { maxBy, minBy, orderBy, find } from 'lodash'
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import clsx from 'clsx'
import { sortOptions } from '../utils'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import '../styles/ratesTable.css'

const useStyles = makeStyles((theme) => ({
  sortContainer: {
    margin: `${theme.spacing(2)}px 0`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortContainerMobile: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  sortFormControl: {
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
    borderRadius: '4px',
    minWidth: '130px',
  },
  sortSelect: {
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  bestRateRow: {
    border: '5px solid rgb(255, 255, 255)',
    borderRadius: '5px',
    width: 'calc(100% + 10px)',
    marginLeft: '-5px',
    boxShadow:
      '0px 0px 10px rgba(44,175,50,0.1), 0px 0px 15px rgba(44,175,50,0.5)',
  },
  disclaimer: {
    padding: theme.spacing(2),
    color: '#ffffffa6',
    fontSize: '0.7rem',
  },
  feedbackLink: {
    textAlign: 'center',
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
function RatesTable(props) {
  const classes = useStyles()
  const {
    data,
    defaultSort = '-netrate',
    width,
    onChange,
    onSuggestProviders,
  } = props

  const maxValueOption = maxBy(data, 'NetRate') || {}
  const fastestOption = minBy(data, 'minDurationHrs') || {}
  data.forEach((item) => {
    if (item.NetRate === maxValueOption.NetRate) {
      item.isBestRate = true
    }
    if (item.minDurationHrs === fastestOption.minDurationHrs) {
      item.isFastest = true
    }
  })

  const sortOption = find(sortOptions, { key: defaultSort }) || {}
  const sortedData = orderBy(
    data,
    [sortOption.field, 'NetRate'],
    [sortOption.order, 'desc']
  )
  const showMobileView = isWidthDown('sm', width)
  const View = showMobileView ? MobileView : DesktopView
  return (
    <div
      className={clsx('rates-table-container', {
        mobile: showMobileView,
        desktop: !showMobileView,
        large: width === 'lg',
        medium: width === 'md',
        small: width === 'sm',
        xsmall: width === 'xs',
      })}
      ref={props.tableRef}
    >
      {showMobileView && (
        <div
          className={clsx(classes.sortContainer, {
            [classes.sortContainerMobile]: showMobileView,
          })}
        >
          <FormControl className={classes.sortFormControl} variant="filled">
            <InputLabel id="sort-select-label">Sort by</InputLabel>
            <Select
              id="sort-select"
              value={defaultSort}
              onChange={(event) => onChange('sort', event.target.value)}
              variant="filled"
              className={classes.sortSelect}
              color="secondary"
            >
              {sortOptions
                .filter((opt) => opt.disabled)
                .map(({ key, label }) => (
                  <MenuItem value={key} key={key}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      <View
        key={defaultSort}
        {...props}
        {...{
          maxValueOption,
          sortOption,
          width,
          bestRateRowClass: classes.bestRateRow,
        }}
        data={sortedData}
      />
      <div className={classes.disclaimer}>
        <b>Disclaimer:</b> We try our best to show you the latest exchange
        rates. However, as rates keeps changing frequently, our rates could be
        slightly outdated.
      </div>
      <div>
        <Typography
          color={'secondary'}
          variant="subtitle2"
          className={classes.feedbackLink}
          onClick={onSuggestProviders}
          data-track-click="true"
          data-track-category="feedback"
          data-track-label="provider-not-supported"
        >
          Is your preferred FX provider not listed here?
        </Typography>
      </div>
    </div>
  )
}

RatesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  defaultSort: PropTypes.string,
  fromCurrency: PropTypes.string.isRequired,
  toCurrency: PropTypes.string.isRequired,
  width: PropTypes.string,
  onChange: PropTypes.func,
  tableRef: PropTypes.object,
  onSuggestProviders: PropTypes.func,
}

export default withWidth()(RatesTable)
