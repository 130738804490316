import React from 'react'
import Header from './Header'
import { ThemeProvider, CssBaseline, LinearProgress } from '@material-ui/core'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import {
  withQueryParams,
  StringParam,
  NumberParam,
  withDefault,
} from 'use-query-params'

import Hero from './Hero'
import InputForm from './InputForm'
import { getLatestRates } from './services/RateService'
import RatesTable from './RatesTable/RatesTableMaster'
import { isEmpty } from 'lodash'
import AboutUs from './AboutUs'
import Footer from './Footer'
import { sortOptions, scrollToElement } from './utils'

import './styles/App.css'
import FeedbackForm from './FeedbackForm'

const sortKeys = Object.keys(sortOptions)

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#061c3e',
      light: '#061b3a',
      dark: '#030d1b',
      darker: '#020915',
    },
    secondary: { dark: '#710728', main: '#b11949', darker: '#520920' },
  },
})
theme = responsiveFontSizes(theme)
window.theme = theme
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingFxData: false,
      fxData: [],
      market: {},
      defaultSort: '-netrate',
      error: null,
      tableRef: React.createRef(),
      feedbackTab: false,
    }
  }

  componentDidUpdate() {
    const isValid = this.fixURLParamsIfNeeded()
    if (isValid) {
      this.findLatestRatesIfNeeded()
    }
  }

  componentDidMount() {
    const isValid = this.fixURLParamsIfNeeded()
    if (isValid) {
      this.findLatestRatesIfNeeded()
    }
  }

  fixURLParamsIfNeeded() {
    const { query, setQuery } = this.props
    const { amount, from, to, sort } = query
    const paramsToFix = {}
    if (!from || from !== 'USD') {
      paramsToFix.from = 'USD'
    }
    if (!to || to !== 'INR') {
      paramsToFix.to = 'INR'
    }
    if (!sort || sortKeys.includes(sort)) {
      paramsToFix.sort = '-netrate'
    }
    if ((!amount && !isEmpty(paramsToFix)) || amount < 0) {
      paramsToFix.amount = 1000
    }
    if (!isEmpty(paramsToFix)) {
      setQuery(paramsToFix)
      return false
    }
    return true
  }

  handleFormChange = (key, value) => {
    const { setQuery } = this.props
    switch (key) {
      case 'from':
      case 'to':
      case 'amount':
      case 'sort':
        setQuery({ [key]: value })
        break
      default:
    }
  }

  handleFeedbackTabChanged = (feedbackTab) => {
    this.setState({ feedbackTab })
  }

  findLatestRatesIfNeeded = () => {
    const { fxKey: stateFxKey } = this.state
    const { from, to, amount } = this.props.query
    const urlFxKey = !from || !to || !amount ? null : `${from}:${to}:${amount}`
    if (urlFxKey && !stateFxKey) {
      // if no data exists
      this.findMarketRate()
    }
  }

  findMarketRate = async () => {
    const { from, to, amount } = this.props.query
    if (!from || !to || !amount) {
      return
    }
    const fxKey = `${from}:${to}:${amount}`
    this.setState({ loadingFxData: true, fxKey })
    try {
      const {
        data: {
          //Vendors,
          Market,
        },
      } = await getLatestRates(from, to, amount)
      this.setState({
        // fxData: Vendors,
        market: Market[0],
        loadingFxData: false,
        fxKey,
        error: null,
      })
    } catch (error) {
      this.setState({
        loadingFxData: false,
        fxKey,
        error: error.message,
      })
    }
  }

  findLatestRates = async (scrollOnResponse) => {
    const { from, to, amount } = this.props.query
    if (!from || !to || !amount) {
      return
    }
    const fxKey = `${from}:${to}:${amount}`
    this.setState({ loadingFxData: true, fxKey })
    try {
      const {
        data: { Vendors, Market },
      } = await getLatestRates(from, to, amount)
      this.setState({
        fxData: Vendors,
        market: Market[0],
        loadingFxData: false,
        fxKey,
        error: null,
      })
      if (scrollOnResponse) {
        this.scrollToTable()
      }
    } catch (error) {
      this.setState({
        loadingFxData: false,
        fxKey,
        error: error.message,
      })
    }
  }

  scrollToTable() {
    try {
      const { tableRef } = this.state
      if (tableRef.current) {
        scrollToElement(tableRef.current, -56) // header-size offset for mobile
      }
    } catch (error) {
      console.error(error)
    }
  }

  handleSuggestions = (key) => () => {
    scrollToElement('#feedback-form')
    this.setState({ feedbackTab: key === 'currencies' ? 1 : 0 })
  }

  render() {
    const {
      fxData,
      loadingFxData,
      market,
      error,
      tableRef,
      feedbackTab,
    } = this.state
    const { from, to, sort, amount } = this.props.query
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Header
            onSuggestCurrencies={this.handleSuggestions('currencies')}
            onSuggestProviders={this.handleSuggestions('providers')}
          />
          <Hero>
            <InputForm
              onChange={this.handleFormChange}
              findRates={this.findLatestRates}
              fromCurrency={from}
              toCurrency={to}
              amount={amount}
              sort={sort}
              loadingFxData={loadingFxData}
              market={market}
              onSuggestCurrencies={this.handleSuggestions('currencies')}
            />
          </Hero>
          <main
            className="main"
            style={{ backgroundColor: theme.palette.primary.light }}
          >
            {loadingFxData && (
              <div className="main-loader">
                <LinearProgress color="secondary" />
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
            {!isEmpty(fxData) && (
              <RatesTable
                data={fxData}
                loading={loadingFxData}
                fromCurrency={from}
                toCurrency={to}
                defaultSort={sort}
                onChange={this.handleFormChange}
                tableRef={tableRef}
                onSuggestProviders={this.handleSuggestions('providers')}
              />
            )}
          </main>
          <FeedbackForm
            onTabChanged={this.handleFeedbackTabChanged}
            feedbackTab={feedbackTab}
            fromCurrency={from}
            toCurrency={to}
          />
          <AboutUs />
          <Footer />
        </div>
      </ThemeProvider>
    )
  }
}

export default withQueryParams(
  {
    amount: NumberParam,
    from: withDefault(StringParam, ''),
    to: withDefault(StringParam, ''),
    sort: withDefault(StringParam, ''),
  },
  App
)
