import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import 'currency-flags/dist/currency-flags.css'

function CurrencyFlag({ currency, size, className = '' }) {
  return (
    <div
      className={clsx(
        'currency-flag',
        `currency-flag-${currency.toLowerCase()}`,
        `currency-flag-${size}`,
        className
      )}
    />
  )
}

CurrencyFlag.propTypes = {
  currency: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
}

export default CurrencyFlag
