import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import {
  CellHeading,
  renderLogo,
  renderDurationWithRating,
  renderYouPay,
  renderNetRate,
  renderYouReceive,
  renderSendMoneyBtn,
} from './common'
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    width: '100vw',
    textAlign: 'center',
    fontSize: '1rem',
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
  },
  row: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    '& .MuiGrid-item': {
      overflow: 'hidden',
    },
    '& .MuiGrid-container': {
      padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
    },
  },
  sendBtnWrapper: {
    position: 'relative',
  },
  logoContainer: {
    position: 'relative',
  },
  youPay: {
    padding: theme.spacing(1),
  },
  youReceive: {
    padding: theme.spacing(1),
  },
}))

function MobileView(props) {
  const {
    data,
    width,
    toCurrency,
    fromCurrency,
    maxValueOption,
    bestRateRowClass,
  } = props
  const bestRateYouReceived = maxValueOption.TotalAmountReceived
  const classes = useStyles()
  return (
    <div className={clsx('mobile-view', classes.container)}>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.list}>
          {data.map((rowData) => {
            return (
              <div
                className={clsx(classes.row, {
                  [bestRateRowClass]: rowData.isBestRate,
                })}
                key={rowData.Name}
              >
                <Grid container alignItems="stretch">
                  <Grid item xs={4} className={classes.logoContainer}>
                    {renderLogo(width)(rowData)}
                  </Grid>
                  <Grid item xs={4} className={classes.youPay}>
                    <CellHeading>You Pay</CellHeading>
                    {renderYouPay(fromCurrency)(rowData)}
                  </Grid>
                  <Grid item xs={4} className={classes.youReceive}>
                    <CellHeading>You Receive</CellHeading>
                    {renderYouReceive(toCurrency, bestRateYouReceived)(rowData)}
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="stretch"
                  style={{ overflow: 'visible' }}
                >
                  <Grid item xs={4}>
                    {renderDurationWithRating(rowData)}
                  </Grid>
                  <Grid item xs={4}>
                    <CellHeading>Net Rate</CellHeading>
                    {renderNetRate(toCurrency)(rowData)}
                  </Grid>
                  <Grid item xs={4}>
                    <div className={classes.sendBtnWrapper}>
                      {renderSendMoneyBtn(width)(rowData)}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )
          })}
        </div>
      </Paper>
    </div>
  )
}

MobileView.propTypes = {
  maxValueOption: PropTypes.object,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  width: PropTypes.string,
  toCurrency: PropTypes.string,
  fromCurrency: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
  bestRateRowClass: PropTypes.string,
}

export default MobileView
