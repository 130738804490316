/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import { isWidthDown } from '@material-ui/core/withWidth'
import { sortOptions } from '../utils'
import { find, get } from 'lodash'
import {
  renderLogo,
  renderRating,
  renderDurationWithRating,
  renderDuration,
  renderNetRate,
  renderYouPay,
  renderYouReceive,
  renderSendMoneyBtn,
} from './common'

const tableOptions = {
  search: false,
  paging: false,
  showTitle: false,
  thirdSortClick: false,
  padding: 'dense',
  draggable: false,
  toolbar: false,
  defaultExpanded: true,
  tableLayout: 'fixed',
}

export default function DesktopView(props) {
  const {
    maxValueOption,
    sortOption,
    width,
    toCurrency,
    fromCurrency,
    loading,
    data,
    onChange,
  } = props

  const hidden = {
    name: false,
    rating: isWidthDown('md', width),
    duration: isWidthDown('sm', width),
    netrate: isWidthDown('sm', width),
    pay: false,
    receive: false,
    sendBtn: false,
  }

  const getSortKey = (field) => {
    const o = find(sortOptions, { field, order: 'asc' })
    return o ? o.key : null
  }
  const getDefaultSort = (field) =>
    field === sortOption.field ? sortOption.order : undefined

  const buildColumns = () => {
    const bestRateYouReceived = maxValueOption.TotalAmountReceived
    return [
      {
        field: 'Name',
        title: 'Name',
        sortKey: getSortKey('Name'),
        defaultSort: getDefaultSort('Name'),
        cellStyle: { position: 'relative', textAlign: 'center' },
        hidden: hidden.name,
        render: renderLogo(width),
      },
      {
        field: 'Rating',
        title: 'Rating',
        sortKey: getSortKey('Rating'),
        defaultSort: getDefaultSort('Rating'),
        hidden: hidden.rating,
        render: renderRating(width),
      },
      {
        field: 'minDurationHrs',
        title: 'Duration',
        sortKey: getSortKey('minDurationHrs'),
        defaultSort: getDefaultSort('minDurationHrs'),
        cellStyle: { textAlign: 'center' },
        headerStyle: { minWidth: '120px' },
        hidden: hidden.duration,
        render: hidden.rating ? renderDurationWithRating : renderDuration,
      },
      {
        field: 'NetRate',
        title: 'Net Rate',
        sortKey: getSortKey('NetRate'),
        defaultSort: getDefaultSort('NetRate'),
        cellStyle: { textAlign: 'center' },
        headerStyle: { minWidth: '130px' },
        hidden: hidden.netrate,
        render: renderNetRate(toCurrency),
      },
      {
        field: 'z_Total_User_Pays',
        title: 'You Pay',
        sortKey: getSortKey('z_Total_User_Pays'),
        defaultSort: getDefaultSort('z_Total_User_Pays'),
        cellStyle: {
          position: 'relative',
          textAlign: 'center',
          minWidth: '150px',
        },
        hidden: hidden.pay,
        render: renderYouPay(fromCurrency),
      },
      {
        field: 'TotalAmountReceived',
        title: 'You Receive',
        sortKey: getSortKey('TotalAmountReceived'),
        defaultSort: getDefaultSort('TotalAmountReceived'),
        cellStyle: {
          position: 'relative',
          textAlign: 'center',
          minWidth: '120px',
        },
        hidden: hidden.receive,
        render: renderYouReceive(toCurrency, bestRateYouReceived),
      },
      {
        sorting: false,
        cellStyle: {
          minWidth: '160px',
          textAlign: 'center',
          position: 'relative',
        },
        hidden: hidden.sendBtn,
        render: renderSendMoneyBtn(width),
      },
    ]
  }
  const columns = buildColumns()
  const handleOnSortChange = (columnIdx, order) => {
    let sortKey = columns[columnIdx].sortKey
    if (order === 'desc') {
      sortKey = `-${sortKey}`
    }
    if (sortKey) {
      onChange('sort', sortKey)
    }
  }

  // fixed header manipulation
  const tableRef = useRef()
  useEffect(() => {
    function fixHeaderClasses() {
      const offsetY = 64
      if (tableRef.current) {
        const el = get(tableRef, 'current.tableContainerDiv.current')
        let headrow = null
        if (el) {
          headrow = el.querySelector('.MuiTableRow-head')
        }
        if (el && headrow) {
          const { top, bottom } = el.getBoundingClientRect()
          if (top < offsetY && bottom > offsetY) {
            headrow.classList.add('fixed-header')
          } else {
            headrow.classList.remove('fixed-header')
          }
        }
      }
    }
    window.addEventListener('scroll', fixHeaderClasses)
    return () => {
      window.removeEventListener('scroll', fixHeaderClasses)
    }
  })
  return (
    <MaterialTable
      onOrderChange={handleOnSortChange}
      data={data}
      columns={columns}
      options={tableOptions}
      isLoading={loading}
      tableRef={tableRef}
    />
  )
}

DesktopView.propTypes = {
  maxValueOption: PropTypes.object,
  sortOption: PropTypes.object,
  width: PropTypes.string,
  toCurrency: PropTypes.string,
  fromCurrency: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
}
