import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import ThankYouMsg from './ThankYouMsg'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { submitProvidersSuggestion } from '../services/FeedbackService'

const EMAIL_LEN_LIMIT = 254
const COMMENT_LEN_LIMIT = 500

const filter = createFilterOptions()
export default function SuggestProviders(props) {
  const {
    classes,
    suggestedProviders,
    setSuggestedProviders,
    vendorOptions,
    refFromCurrency,
    refToCurrency,
  } = props
  const [showMsg, setShowMsg] = useState(false)
  const [emailId, setEmailId] = useState('')
  const [comment, setComment] = useState('')
  const submitForm = (e) => {
    e.preventDefault()
    submitProvidersSuggestion({
      providers: suggestedProviders,
      selectedFromCurrency: refFromCurrency,
      selectedToCurrency: refToCurrency,
      emailId,
      comment,
    })
    setSuggestedProviders([])
    setEmailId('')
    setComment('')
    setShowMsg(true)
  }
  const setValue = setSuggestedProviders
  return (
    <>
      <form onSubmit={submitForm} className={classes.feedbackForm}>
        <Autocomplete
          value={suggestedProviders}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setValue({
                name: newValue,
              })
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setValue({
                name: newValue.inputValue,
              })
            } else {
              setValue(newValue)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.unshift({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              })
            }

            return filtered
          }}
          selectOnFocus
          clearOnEscape
          id="vendor-suggestion"
          options={vendorOptions}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            if (option.inputValue) {
              return option.inputValue
            }
            // Regular option
            return option.name
          }}
          renderOption={(option) => (
            <div className={classes.vendorOption}>
              <div className="vName">{option.name}</div>
              <div className="vUrl">{option.url}</div>
            </div>
          )}
          freeSolo
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type Provider names or URLs"
              variant="outlined"
            />
          )}
        />
        <TextField
          id="fb-email"
          label="Your Email ID (OPTIONAL)"
          value={emailId}
          onChange={(e) => {
            const newValue = e.target.value || ''
            setEmailId(newValue.substring(0, EMAIL_LEN_LIMIT))
          }}
          helperText="We won't spam you! We will notify you once we add suggested providers."
          placeholder={'sample'}
          variant="outlined"
          color="secondary"
          type="email"
          autoComplete="on"
        />
        <TextField
          id="fb-comment"
          label="Comments (OPTIONAL)"
          value={comment}
          onChange={(e) => {
            const newValue = e.target.value || ''
            setComment(newValue.substring(0, COMMENT_LEN_LIMIT))
          }}
          helperText={`Characters left: ${comment.length}/${COMMENT_LEN_LIMIT}`}
          placeholder={'sample'}
          variant="outlined"
          color="secondary"
          multiline
          rowsMax={5}
        />
        <div>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            className={classes.submitBtn}
            disabled={!suggestedProviders || suggestedProviders.length === 0}
            data-track-click="true"
            data-track-category="feedback"
            data-track-label="suggest-provider-submit"
          >
            Submit Feedback
          </Button>
        </div>
      </form>
      <ThankYouMsg show={showMsg} classes={classes} type="vendor" />
    </>
  )
}
