import React from 'react'
import { Tooltip, isWidthDown, Button } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import Timeago from 'react-timeago'
import clsx from 'clsx'
import { extractHostName, toLocalTimeStamp } from '../utils'
import CurrencyValue from '../components/CurrencyValue'
import Badge from '../components/Badge'

export function CellHeading(props) {
  return <div className="cell-heading">{props.children}</div>
}

export const renderLogo = (width) => (rowData) => {
  const label = getLabelText(rowData)
  return (
    <Tooltip title={rowData.Name} placement="right">
      <div className="logo-container">
        {/* {rowData.isBestRate && <Badge label="Best Rate" />} */}
        {label && <div className="label">{label}</div>}
        <div
          className="logo-img-container"
          style={{ width: isWidthDown('xs', width) ? 100 : 140 }}
        >
          <img
            className="logo-img"
            src={rowData.IconImage}
            alt={rowData.Name}
          />
        </div>
      </div>
    </Tooltip>
  )
}

const getRatingTitle = (rowData) => {
  if (rowData.Rating == null) {
    return 'No Ratings found'
  }
  let title = `${rowData.Rating}/5`
  let hostname = extractHostName(rowData.RatingSource)
  if (hostname) {
    hostname = hostname.replace(/^www\./, '')
    title += ` - ${hostname}`
  }
  return title
}

export const renderRating = (width) => (rowData) => (
  <Tooltip title={getRatingTitle(rowData)} placement="right">
    <div className="ratings">
      <Button
        href={rowData.RatingSource}
        target="_blank"
        disabled={!rowData.RatingSource}
        data-track-click="true"
      >
        <Rating
          value={rowData.Rating}
          readOnly
          precision={0.5}
          size={isWidthDown('lg', width) ? 'medium' : 'small'}
        />
      </Button>
    </div>
  </Tooltip>
)

export const renderDuration = (rowData) => (
  <div className={clsx('duration-wrapper', { 'is-best': rowData.isFastest })}>
    <span className="duration">{`${rowData.Duration} ${rowData.DurationType}`}</span>
  </div>
)

export const renderDurationWithRating = (rowData) => (
  <>
    <div className={clsx('duration-wrapper', { 'is-best': rowData.isFastest })}>
      <span className="duration">{`${rowData.Duration} ${rowData.DurationType}`}</span>
    </div>
    <Tooltip title={getRatingTitle(rowData)} placement="top">
      <div className="ratings">
        <Button
          href={rowData.RatingSource}
          target="_blank"
          disabled={!rowData.RatingSource}
          data-track-click="true"
        >
          <Rating
            value={rowData.Rating}
            readOnly
            precision={0.5}
            size="small"
          />
        </Button>
      </div>
    </Tooltip>
  </>
)

export const renderNetRate = (toCurrency) => (rowData) => (
  <div>
    <div className={clsx({ 'is-best': rowData.isBestRate })}>
      <CurrencyValue value={rowData.NetRate} currency={toCurrency} />
      <div className="time-ago">
        <Timeago date={toLocalTimeStamp(rowData.TimeStamp_UTC)} />
      </div>
    </div>
  </div>
)

export const renderYouPay = (fromCurrency) => (rowData) => {
  const extraFees =
    rowData.z_Fees_Over_Incl === 'Over' ? rowData.z_FeesConfigured : false
  return (
    <div>
      <div>
        <CurrencyValue
          value={rowData.z_Total_User_Pays}
          currency={fromCurrency}
        />
      </div>
      <div className={'extra-fees'}>
        {extraFees > 0 && (
          <span>
            Includes{' '}
            <span>
              <CurrencyValue value={extraFees} currency={fromCurrency} /> Fee
            </span>
          </span>
        )}
      </div>
    </div>
  )
}

export const renderYouReceive = (toCurrency, bestAmount) => (rowData) => {
  const difference = rowData.TotalAmountReceived - bestAmount
  return (
    <div>
      <div>
        <CurrencyValue
          value={rowData.TotalAmountReceived}
          currency={toCurrency}
        />
      </div>
      <div
        className={clsx('price-difference', {
          negative: difference < 0,
          positive: difference > 0,
        })}
      >
        {difference !== 0 && (
          <CurrencyValue value={difference} currency={toCurrency} />
        )}
      </div>
    </div>
  )
}

export const renderSendMoneyBtn = (width) => (rowData) => (
  <div
    className={clsx('send-btn-wrapper', { small: isWidthDown('xs', width) })}
  >
    {rowData.isBestRate && <Badge label="Best Rate" />}
    <Button
      // onClick={() => {
      //   window.open(rowData.GoToLink, '_blank')
      // }}
      href={rowData.GoToLink}
      target="_blank"
      variant="contained"
      color="primary"
      className="send-btn"
      data-track-click="true"
    >
      {isWidthDown('xs', width) ? 'Send' : 'Send money'}
    </Button>
  </div>
)

function getLabelText(rowData) {
  return rowData.Name.split(/\s*-\s*/)[1]
}
