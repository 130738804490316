import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Divider,
  Button,
  InputLabel,
  Typography,
} from '@material-ui/core'
import CurrencySelect from './components/CurrencySelect'
import CurrencyInput from './components/CurrencyInput'
import { makeStyles } from '@material-ui/styles'
import { getSymbol } from './currencyList'
import CurrencyValue from './components/CurrencyValue'
import Timeago from 'react-timeago'
import { toLocalTimeStamp } from './utils'

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    minHeight: '300px',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  currencySelect: {
    minWidth: '150px',
    margin: theme.spacing(2),
  },
  currencyInput: {
    margin: theme.spacing(4),
  },
  actionBtn: {
    display: 'block',
    width: '100%',
    padding: '16px',
  },
  marketRate: {
    fontSize: '1.2rem',
    '& .mr__label': {
      fontSize: '12px',
      margin: '20px 0 4px',
      color: theme.palette.secondary.main,
    },
    '& .currency-value': {
      color: theme.palette.primary.light,
    },
    '& .mr__equal-sign': {
      color: theme.palette.secondary.main,
    },
  },
  feedbackLink: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

function InputForm(props) {
  const {
    fromCurrency,
    toCurrency,
    amount,
    onChange,
    loadingFxData,
    findRates,
    market: { Rate: marketRate, TimeStamp_UTC: mrTimestamp } = {},
    maxAmount,
    onSuggestCurrencies,
  } = props
  const classes = useStyles()
  const isActionDisabled =
    !fromCurrency || !toCurrency || !amount || loadingFxData
  const submitForm = (e) => {
    e.preventDefault()
    findRates(true)
  }
  return (
    <Paper elevation={3} className={classes.formWrapper}>
      <form onSubmit={submitForm}>
        <CurrencySelect
          id="send-currency-select"
          label="Send Currency"
          className={classes.currencySelect}
          onChange={(value) => onChange('from', value)}
          value={fromCurrency}
          getOptionDisabled={(opt) => opt.value !== 'USD'}
        />
        <CurrencySelect
          id="receive-currency-select"
          label="Receive Currency"
          className={classes.currencySelect}
          value={toCurrency}
          onChange={(value) => onChange('to', value)}
          getOptionDisabled={(opt) => opt.value !== 'INR'}
        />
        <Typography
          color={'secondary'}
          variant="subtitle2"
          className={classes.feedbackLink}
          onClick={onSuggestCurrencies}
          data-track-click="true"
          data-track-category="feedback"
          data-track-label="currency-not-supported"
        >
          Is your currency not supported?
        </Typography>
        <Divider />
        {marketRate && (
          <div className={classes.marketRate}>
            <InputLabel className="mr__label">Market Rate</InputLabel>
            <CurrencyValue currency={fromCurrency} value={1} />
            <span className="mr__equal-sign">&nbsp;=&nbsp;</span>
            <CurrencyValue currency={toCurrency} value={marketRate} />
            <div className="time-ago">
              <Timeago date={toLocalTimeStamp(mrTimestamp)} />
            </div>
          </div>
        )}
        <CurrencyInput
          label="Amount to Send"
          value={amount}
          className={classes.currencyInput}
          inputProps={{
            isAllowed: (values) =>
              values.value === '' || values.floatValue <= maxAmount,
          }}
          onChange={(values) => onChange('amount', values.value)}
          symbol={getSymbol(fromCurrency)}
          // autoFocus
        />
        <br />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={classes.actionBtn}
          disabled={isActionDisabled}
          data-track-click="true"
          data-track-category="search"
          data-track-label={`find-rates-${fromCurrency}-${toCurrency}`}
          data-track-value={amount}
        >
          Find Rates
        </Button>
      </form>
    </Paper>
  )
}

InputForm.propTypes = {
  onChange: PropTypes.func,
  fromCurrency: PropTypes.string,
  toCurrency: PropTypes.string,
  amount: PropTypes.number,
  loadingFxData: PropTypes.bool,
  findRates: PropTypes.func,
  market: PropTypes.object,
  maxAmount: PropTypes.number,
  onSuggestCurrencies: PropTypes.func,
}

InputForm.defaultProps = {
  maxAmount: 100000,
}

export default InputForm
