import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Tabs, Tab, Box, Typography, ExpansionPanel } from '@material-ui/core'
import CurrencyIcon from '@material-ui/icons/Euro'
import ProviderIcon from '@material-ui/icons/Language'
import { getFeedbackProvidersOptions } from '../services/FeedbackService'
import SuggestProviders from './SuggestProviders'
import SuggestCurrency from './SuggestCurrency'

const propTypes = {
  feedbackTab: PropTypes.oneOf([0, 1, false]),
  onTabChanged: PropTypes.func,
  fromCurrency: PropTypes.string,
  toCurrency: PropTypes.string,
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  feedbackTitle: {
    margin: 'auto',
    color: '#fff',
    textAlign: 'center',
    fontSize: '1.2rem',
    padding: '2rem 4rem',
    maxWidth: '850px',
  },
  tabWrapper: {
    padding: theme.spacing(2),
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    '& .MuiTabs-root': {
      color: theme.palette.secondary.main,
      maxWidth: '1000px',
      margin: 'auto',
    },
    '& .MuiTab-root': {
      fontSize: '1rem',
      opacity: 0.8,
      color: '#fff',
      '&:hover': {
        opacity: 1,
      },
      '&.Mui-selected': {
        opacity: 1,
        color: theme.palette.secondary.main,
      },
    },
    '& .MuiSvgIcon-root': {},
  },
  tabPanelWrapper: {
    maxWidth: '1000px',
    margin: 'auto !important',
    width: '100%',
  },
  tabPanel: {
    minHeight: '180px',
    color: theme.palette.secondary.light,
  },
  currencySelect: {
    minWidth: '250px',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  submitBtn: {
    minWidth: '200px',
    padding: '1rem',
  },
  feedbackForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  vendorOption: {
    '& .vUrl': {
      fontSize: '0.8em',
      color: theme.palette.text.secondary,
    },
  },
  thankyouText: {
    maxWidth: '580px',
    margin: '1em auto 2em',
    fontSize: '1.8rem',
    textAlign: 'center',
    color: theme.palette.primary.main,
    display: 'block',
    '& div': {
      fontSize: '1.2rem',
      marginTop: '1rem',
    },
  },
}))

function FeedbackForm(props) {
  const classes = useStyles()
  const {
    feedbackTab,
    onTabChanged,
    fromCurrency: refFromCurrency,
    toCurrency: refToCurrency,
  } = props
  const handleTabChange = (e, value) => {
    const newTab = feedbackTab === value ? false : value
    onTabChanged(newTab)
  }

  const [fromCurrency, setFromCurrency] = useState('')
  const [toCurrency, setToCurrency] = useState('')
  const [suggestedProviders, setSuggestedProviders] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])
  useEffect(() => {
    const options = getFeedbackProvidersOptions()
    setVendorOptions(options)
  }, [])
  return (
    <div id="feedback-form" className={classes.root}>
      <div className={classes.tabWrapper}>
        <Typography className={classes.feedbackTitle}>
          We are constanly adding new forex provider websites and currenices. If
          you have a specific provider or currency suggestion that you would
          like to see here, let us know and we will try to prioritize it.
        </Typography>
        <Tabs
          value={feedbackTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          scrollButtons="auto"
          centered
        >
          <Tab
            label="Suggest Providers"
            value={0}
            icon={<ProviderIcon fontSize={'large'} />}
            data-track-click="true"
            data-track-category="feedback"
            data-track-label="suggest-provider-tab"
          />
          <Tab
            label="Suggest Currencies"
            value={1}
            icon={<CurrencyIcon fontSize={'large'} />}
            data-track-click="true"
            data-track-category="feedback"
            data-track-label="suggest-currency-tab"
          />
        </Tabs>
      </div>
      <ExpansionPanel
        className={classes.tabPanelWrapper}
        expanded={feedbackTab !== false}
        elevation={0}
      >
        <TabPanel value={feedbackTab} index={0} classes={classes}>
          <SuggestProviders
            classes={classes}
            {...{
              suggestedProviders,
              setSuggestedProviders,
              vendorOptions,
              refFromCurrency,
              refToCurrency,
            }}
          />
        </TabPanel>
        <TabPanel value={feedbackTab} index={1} classes={classes}>
          <SuggestCurrency
            classes={classes}
            {...{
              fromCurrency,
              toCurrency,
              setFromCurrency,
              setToCurrency,
            }}
          />
        </TabPanel>
      </ExpansionPanel>
    </div>
  )
}

FeedbackForm.propTypes = propTypes

function TabPanel(props) {
  const { classes, children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  classes: PropTypes.object,
}

export default FeedbackForm
