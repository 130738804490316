import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@material-ui/core'
import NumberFormat from 'react-number-format'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          formatted: values.formattedValue,
          value: values.floatValue,
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

function CurrencyInput(props) {
  const { label, symbol, value, ...others } = props
  return (
    <TextField
      label={label}
      InputProps={{
        startAdornment: symbol && (
          <InputAdornment position="start">{symbol}</InputAdornment>
        ),
        inputComponent: NumberFormatCustom,
      }}
      variant="outlined"
      value={value || ''}
      {...others}
    />
  )
}

CurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  value: PropTypes.number,
}

CurrencyInput.defaultProps = {
  label: 'Amount',
}

export default CurrencyInput
