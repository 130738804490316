import { find, upperCase, orderBy } from 'lodash'
import MasterList from './currencyMaster'
const orderList = {
  INR: 1,
  USD: 2,
  CAD: 3,
  AED: 4,
  EUR: 5,
  GBP: 6,
}

let unorderedList = Object.keys(MasterList).map((code) => ({
  value: code,
  label: MasterList[code].name,
  symbol: MasterList[code].symbol,
  order: orderList[code] || 100,
}))

const currencyList = orderBy(unorderedList, ['order', 'code'], ['asc', 'asc'])

export function getCurrency(code = '') {
  return find(currencyList, { value: upperCase(code) })
}

export function getSymbol(code = '') {
  const curr = find(currencyList, { value: upperCase(code) })
  return curr ? curr.symbol : ''
}
export default currencyList
