import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import EmailIcon from '@material-ui/icons/Email'
import AboutUsIcon from '@material-ui/icons/People'
import CurrencyIcon from '@material-ui/icons/Euro'
import ProviderIcon from '@material-ui/icons/Language'
import logoURL from './assets/whitetxt-logo.png'

import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useScrollTrigger,
} from '@material-ui/core'
import clsx from 'clsx'
import { scrollToElement } from './utils'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#030d1b96',
    transition: 'background 0.3s',
    color: '#ffffff',
  },
  topRoot: {
    background: '#030d1b96',
  },
  scrolledRoot: {
    background: '#030d1b',
  },
  menuButton: ({ mobileView }) => ({
    marginRight: theme.spacing(2),
    position: 'relative',
    zIndex: 2,
    display: mobileView ? 'flex' : 'none',
  }),
  buttons: ({ mobileView }) => ({
    display: mobileView ? 'none' : 'block',
    '& .MuiButton-text': {
      opacity: 0.7,
    },
    '& .MuiButton-text:hover': {
      opacity: 1,
    },
  }),
  logoWrapper: ({ mobileView }) => ({
    flexGrow: 1,
    textAlign: mobileView ? 'center' : 'left',
    position: mobileView ? 'absolute' : 'relative',
    width: mobileView ? 'calc(100% - 36px)' : 'auto',
    filter: 'brightness(1.2)',
  }),
  list: {
    width: 245,
  },
  fullList: {
    width: 'auto',
  },
  drawerRoot: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.main,
  },
}))

function ScrollTrigger(props) {
  const { children, classes } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: clsx(
      classes.root,
      trigger ? classes.scrolledRoot : classes.topRoot
    ),
  })
}

function Header(props) {
  const { onSuggestCurrencies, onSuggestProviders } = props
  const [drawerOpen, setDrawerOpen] = useState(false)
  const mobileView = isWidthDown('xs', props.width)
  const classes = useStyles({
    mobileView,
  })
  return (
    <>
      <ScrollTrigger {...props} mobileView={mobileView} classes={classes}>
        <AppBar
          position="fixed"
          color="transparent"
          elevation={0}
          className={classes.root}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" className={classes.logoWrapper}>
            Get Best FX
          </Typography> */}
            <div className={classes.logoWrapper}>
              <img src={logoURL} alt="getBestFX.com" height="40" />
            </div>
            <div className={classes.buttons}>
              <Button
                color="inherit"
                onClick={() => scrollToElement('#about-us')}
                data-track-click="true"
                data-track-category="link"
                data-track-label="about-us-nav"
              >
                About Us
              </Button>
              <Button
                color="inherit"
                onClick={onSuggestProviders}
                data-track-click="true"
                data-track-category="feedback"
                data-track-label="suggest-provider-nav"
              >
                Suggest Providers
              </Button>
              <Button
                color="inherit"
                onClick={onSuggestCurrencies}
                data-track-click="true"
                data-track-category="feedback"
                data-track-label="suggest-currency-nav"
              >
                Suggest Currencies
              </Button>

              <Button
                color="inherit"
                onClick={() => scrollToElement('#footer')}
                data-track-click="true"
                data-track-category="link"
                data-track-label="contact-nav"
              >
                Contact
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </ScrollTrigger>
      {mobileView && (
        <SwipeableDrawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          classes={{
            paper: classes.drawerRoot,
          }}
        >
          <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={() => setDrawerOpen(false)}
            onKeyDown={() => setDrawerOpen(false)}
          >
            <List>
              <ListItem
                button
                onClick={() => scrollToElement('#about-us')}
                data-track-click="true"
                data-track-category="link"
                data-track-label="about-us-nav"
              >
                <ListItemIcon>
                  <AboutUsIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="About Us" />
              </ListItem>
              <ListItem
                button
                onClick={onSuggestProviders}
                data-track-click="true"
                data-track-category="feedback"
                data-track-label="suggest-provider-nav"
              >
                <ListItemIcon>
                  <ProviderIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Suggest Providers" />
              </ListItem>
              <ListItem
                button
                onClick={onSuggestCurrencies}
                data-track-click="true"
                data-track-category="feedback"
                data-track-label="suggest-currency-nav"
              >
                <ListItemIcon>
                  <CurrencyIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Suggest Currencies" />
              </ListItem>
              <ListItem
                button
                onClick={() => scrollToElement('#footer')}
                data-track-click="true"
                data-track-category="link"
                data-track-label="contact-nav"
              >
                <ListItemIcon>
                  <EmailIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      )}
    </>
  )
}

export default withWidth()(Header)
