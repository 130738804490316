import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'
import { Container } from '@material-ui/core'
import CompareIcon from '@material-ui/icons/SettingsEthernet'
import SimplifyIcon from '@material-ui/icons/AllInclusive'
import FreeIcon from '@material-ui/icons/MoneyOff'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'pre-line',
    },
  },
  card: {
    padding: '2rem',
    fontSize: '1.5rem',
    backgroundColor: 'transparent',
    color: 'white',
  },

  card__Title: {
    fontSize: '2rem',
    letterSpacing: '2px',
  },
  card__Text: {
    marginBottom: '1rem',
  },
  card__Point: {
    padding: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.main}52`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  card__Point__Icon: {
    fontSize: '1.8rem',
    transform: 'scale(2)',
    flexGrow: 0,
    color: theme.palette.secondary.main,
    marginTop: '1rem',
  },
  card__Point__Text: {
    fontSize: '1.2rem',
    fontWeight: '100',
    letterSpacing: '1px',
    flexGrow: 1,
    marginLeft: '3rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '1rem',
      textAlign: 'center',
    },
  },
  card__Highlight: {
    color: theme.palette.secondary.main,
    fontSize: '1.8rem',
    textAlign: 'left',
    letterSpacing: '2px',
  },
}))
function AboutUs() {
  const classes = useStyles()
  return (
    <section className={clsx('about-us', classes.root)} id="about-us">
      <Container maxWidth="md">
        <div className={classes.card}>
          <div className={classes.card__Title}>
            Aren&apos;t you tired of hidden fees and complex comparisons while
            trying to find the best FX rate across the internet?
          </div>
          <br />
          <div className={classes.card__Text}>
            Well, we are! That’s why we decided to simplify this for everyone!
          </div>
          <div className={classes.card__Text}>
            We help you compare forex providers on multiple parameters: fees,
            net exchange rate, duration, and more importantly, how much you pay
            and how much you receive.
          </div>
          <br />
          <div className={classes.card__Point}>
            <div className={classes.card__Point__Icon}>
              <CompareIcon />
            </div>
            <div className={classes.card__Point__Text}>
              Select the cheapest service provider AND save money on your
              transfer by comparing multiple forex providers.
            </div>
          </div>
          <div className={clsx(classes.card__Point)}>
            <div className={classes.card__Point__Icon}>
              <SimplifyIcon />
            </div>
            <div className={classes.card__Point__Text}>
              Simplify calculations down to how much you pay and receive, while
              doing an apple to apple comparison.
            </div>
          </div>
          <div className={classes.card__Point}>
            <div className={classes.card__Point__Icon}>
              <FreeIcon />
            </div>
            <div className={classes.card__Point__Text}>
              Our service is 100% FREE. And we intend to keep it that way.
            </div>
          </div>
          <br />
          <div className={classes.card__Highlight}>
            {`No more hidden fees.\nNo more bad exchange rates.`}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default AboutUs
